import $ from 'jquery';
import 'what-input';

// Foundation JS relies on a global varaible. In ES6, all imports are hoisted
// to the top of the file so if we used`import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';
import './lib/jquery.touchSwipe.min';
import './lib/jquery.magnific-popup.min';


$(document).foundation();


$(document).ready(function () {

    // Contact Form send
    if ($('#contact').length) {
      $('#contact').on("formvalid.zf.abide", function (ev, frm) {
        $.ajax({
          type: "POST",
          url: 'contact.php',
          dataType: 'json',
          cache: false,
          data: $('form').serialize(),
          success: function(data) {

            if(data.info === 'error'){
              console.log(data.msg);
            }
            if(data.info === 'success') {
              console.log(data.msg);
            } else {
              console.log(data.msg);
            }
          }
        });
        $('.success.callout').parent().show();
        $('#contact')[0].reset();

      }).on("submit", function(ev) {
            ev.preventDefault();
            console.log("Submit for form id "+ev.target.id+" intercepted");
          });
    }

  $("body").swipe({
    swipeLeft: function(event, direction, distance, duration, fingerCount) {
      if ($(".mfp-gallery").length) {
        $(".mfp-arrow-left").magnificPopup("prev");
      }
    },
    swipeRight: function() {
      if ($(".mfp-gallery").length) {
        $(".mfp-arrow-right").magnificPopup("next");
      }
    },
    threshold: 10
  });
  $("body").swipe('disable'); // otherwise selecting of text in browser not possible

  // gallery setup
  $.extend(true, $.magnificPopup.defaults, {
    tClose: 'Schließen',
    tLoading: 'Wird geladen...',
    gallery: {
      tPrev: 'Zurück',
      tNext: 'Weiter',
      tCounter: '%curr% von %total%'
    },
    image: {
      tError: 'Das <a href="%url%">Bild</a> konnte nicht geladen werden.'
    },
    ajax: {
      tError: 'Der <a href="%url%">Inhalt</a> konnte nicht geladen werden.'
    }
  });

  // gallery images
  $('div.popup-gallery').each(function() {
    $(this).magnificPopup({
      delegate: 'a',
      type: 'image',
      fixedContentPos: 'auto',
      fixedBgPos: false,
      overflowY: 'hidden',
      closeOnContentClick: false,
      removalDelay: 300,
      mainClass: 'mfp-with-fade',
      image: {
        cursor: '',
        titleSrc: 'data-title'
      },
      gallery: {
        enabled: true,
        preload: [1,2],
        navigateByImgClick: true
      },
      callbacks: {
        beforeOpen: function() {
          $("body").swipe('enable');
        },
        beforeClose: function() {
          $("body").swipe('disable');
        }
      }
    });
  });

  $(window).scroll(function() {
    if ($(this).scrollTop() >= 50) {
      $('#return-to-top').fadeIn(200);
    } else {
      $('#return-to-top').fadeOut(200);
    }
  });
  $('#return-to-top').click(function() {
    $('body,html').animate({
      scrollTop : 0
    }, 500);
  });
});
